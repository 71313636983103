@font-face {
	font-family: 'Plickers Lato';         
	src: url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Medium.setcetera.woff2') format('woff2'), url('https://assets.plickers.com/fonts/PlickersLato/PlickersLato-Medium.setcetera.woff') format('woff');    
	font-style: normal;
	font-display: auto;
	font-weight: 500;
	text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'Plickers Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
